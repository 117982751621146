import React, { createContext, Component } from "react";

import translate from "../content/translate.json";

export const LanguageContext = createContext("en");

class LanguageProvider extends Component {

  constructor(props) {
    super(props);

    const splitURL = window.location.pathname.split("/");
    this.translate = translate;
    let lang = !splitURL.includes("en") ? "ar" : "en";
    let dir = lang === "ar" ? "rtl" : "";

    this.html = document.querySelector("html");
    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);

    this.htmlTitle = document.querySelector("title");
    this.htmlTitle.innerHTML = lang === "ar" ? " كريتا الجديدة  " : "The New CRETA";

    this.state = {
      lang,
      translate: translate[lang],
      dir
    };

    window.lang = lang;
  }

  checkLang() {
    const splitURL = window.location.pathname.split("/");
    const lang = !splitURL.includes("en") ? "ar" : "en";
    const dir = lang === "ar" ? "rtl" : "";
    if (this.state.lang !== lang) {
      const translate = this.translate[lang];
      this.setState({ lang, translate, dir });
    }
  }

  toggleLang = lang => {

    window.lang = lang;
    // const splitLang = lang === "ar" ? "en" : "/";

    const splitURL = window.location.pathname.split("/");

    if (splitURL.includes(lang)) return false;

    this.html.setAttribute("lang", lang);
    localStorage.setItem("lang", lang);
    
    this.htmlTitle.innerHTML = lang === "ar" ? " كريتا الجديدة  " : "The New CRETA";


    let newUrl = window.location.pathname.split("/")
      .map( link => {
        if ( link !== "en") {
          link = link && link[0] !== "/" && ("/"+link)
          return link ? link : "";
        }})

    const prevUrl = newUrl[0] + newUrl[1];


    const nextUrl = newUrl.filter( (el, index) => index > 1).join("")


    /*let newUrl = window.location.pathname.split(splitLang)
      .map( link => {

      link = link && link[0] !== "/" && ("/"+link)
        console.log(link)
      return link ? link : "";}).filter(Boolean).join("");

*/
    /*let newUrl = window.location.pathname.split(splitLang)
      .map(link => {
        link = link.split("/").join("");
        return link ? link : "";
      }).filter(Boolean);*/

    newUrl = `${prevUrl}${lang === "en" ? "/en" : ""}`+ nextUrl +window.location.search;

    window.history.replaceState("", "", newUrl);

    let dir = lang === "ar" ? "rtl" : "";

    const translate = this.translate[lang];
    this.setState({ lang, translate, dir });
  };

  getTranslate = (key) => {
    let { translate } = this.state;
    if (key === undefined) return "";
    if (typeof key === "boolean") return key;
    const keys = key.split(".");
    const keysLength = keys.length;
    if (keysLength < 1) return key;
    for (let index = 0; index < keysLength; index++) {
      if (!translate?.[keys[index]]) return key;
      translate = translate[keys[index]];
    }
    return translate;
  };

  render() {
    return (
      <LanguageContext.Provider value={{
        ...this.state,
        toggleLang: this.toggleLang.bind(this),
        getTranslate: this.getTranslate.bind(this),
        checkLang: this.checkLang.bind(this)
      }}>
        {this.props.children}
      </LanguageContext.Provider>
    )
  }
}

export default LanguageProvider;
